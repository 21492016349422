.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.container {
  background-color: black;
}

.mini-logo {
  width: 15%;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.messages {
  font-family: "Montserrat", Sans-serif;
  font-size: 34px;
  font-weight: 200;
  letter-spacing: 3.7px;
}

.name {
  font-family: "Montserrat", Sans-serif;
  font-size: 68px;
  font-weight: 700;
}

.check {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 100;
}

.imagem-redonda {
  width: 20%;
  height: 20%;
  margin-bottom: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.5);
}
