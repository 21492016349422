.rest-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    color: gold;
  }

  .background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
  
  .company-logo {
    width: 1000px; 
    height: auto;
    margin-bottom: 20px;
  }
  