/* participants.css */

.participants-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.participant-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.participant-card img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
}

/* Estilo para o link de navegação de volta */
.back-link {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.back-link:hover {
  text-decoration: underline;
}

/* Adicione estas regras CSS para estilizar a tabela */

.participants-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.participants-table th, .participants-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.participants-table th {
  background-color: #f2f2f2;
}

.participant-image {
  max-width: 100px;
  height: auto;
}
