.create-participant-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.create-participant-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.create-participant-form div {
  margin-bottom: 15px;
}

.create-participant-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.create-participant-form input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-participant-form button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-participant-form button:hover {
  background-color: #45a049;
}

.message {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  font-size: 16px;
  text-align: center;
}
